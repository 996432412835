<template>
  <b-row>
    <b-col sm="12">
      <iq-card class="iq-card">
        <div class="iq-card-body profile-page p-0">
          <div class="profile-header">
            <div class="cover-container"> <img src="../../../assets/images/page-img/profile-banner.jpg" alt="profile-bg" class="rounded img-fluid"> </div>
            <div class="user-detail text-center mb-3">
              <div class="profile-img"> 
                <img v-if='user.avatar' :src="user.avatar" width="150" alt="user" > 
                <img v-else  src="../../../assets/images/user-icon.svg" alt="user" width="150">
              </div>
              <div class="profile-detail">
                <h3 class="">{{user.name}}</h3>
              </div>
            </div>
            <div class="profile-info p-4 d-flex align-items-center justify-content-between position-relative">
              <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
                <li><router-link to="/user/profile-edit"><i class="ri-pencil-line"></i></router-link></li>
              </ul>
              <div class="social-info py-4">
               
              </div>
            </div>
          </div>
        </div>
      </iq-card>
      <iq-card class="iq-card">
        <div class="iq-card-body p-0">
          <div class="user-tabing">
            <!-- <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <tab-nav-items class="col-sm p-0" :active="true" id="pills-feed-tab" href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="true" @click="filterFun('authposts')" title="Timeline" />
              <tab-nav-items class="col-sm p-0" :active="false" id="pills-activity-tab" href="#profile-activity" ariaControls="pills-profile" role="tab" :ariaSelected="false" title="About" />
              <tab-nav-items class="col-sm p-0" :active="false" id="pills-profile-tab" href="#profile-profile" ariaControls="pills-photos" role="tab" :ariaSelected="false" title="Photos" />
              <tab-nav-items class="col-sm p-0" :active="false" id="pills-profile-tab" href="#profile-music" ariaControls="pills-music" role="tab" :ariaSelected="false" title="Music" />
               <tab-nav-items class="col-sm p-0" :active="false" id="pills-tips-tab" href="#profile-tips" @click="filterFun('authtips')" ariaControls="pills-tips" role="tab" :ariaSelected="false" title="Tips" />
            </tab-nav> -->
            <ul class="iq-edit-profile d-flex nav nav-pills">
              <li class="col-md-2 p-0"> <a class="nav-link active" data-toggle="pill" href="#profile-feed" @click="filterFun('authposts')"> Timeline</a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#profile-activity"> About </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#profile-profile" >  Photos </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#profile-music"> Music</a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#profile-tips" @click="filterFun('authtips')" > Tips</a> </li>
          </ul>
          </div>
        </div>
      </iq-card>
    </b-col>
    <b-col sm="12">
      <div class="tab-content">
        <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <div class="iq-card-body p-0">
            <b-row>
              <b-col lg="4">              
             <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
                <events/>        
              </b-col>
              <b-col lg="8">
                <div id="post-modal-data" class="iq-card">
                  <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
                  <div  v-for="post in socialPosts" :key="post.id">
                    <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi' ></social-post>
                  </div>
                  <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                  <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
                </div>
              </b-col>
            </b-row>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="profile-activity" aria-labelled-by="pills-activity-tab">
          <div class="iq-card">
            <div class="iq-card-body">
              <About />
            </div>
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
          <iq-card>
            <template v-slot:body>
              <h2>Photos</h2>
              <tab-nav :pills="true" id="pills" class="nav nav-pills d-flex align-items-center justify-content-left friend-list-items p-0 mb-2">
                <tab-nav-items liClass=" " :active="true" id="pills-photo-you-tab" href="#photo-you" ariaControls="pills-photo-you" role="tab" :ariaSelected="true" title="Photo of you" />
                <!-- <tab-nav-items liClass=" " :active="false" id="pills-your-photo-tab" href="#your-photo" ariaControls="pills-your-photo" role="tab" :ariaSelected="false" title="your photo" /> -->
              </tab-nav>
              <div class="tab-content">
                <tab-content-item :active="true" id="photo-you" aria-labelled-by="pills-photo-you-tab">
                  <b-row>
                  
                    <b-col v-for="(item,index) in postphotos" :key="index" md="12" lg="6" class="mb-3 py-2 px-2" style='box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'>
                      <!-- <div v-for="(ph,i) in item.media" :key="i">
                        <p>{{ph}}</p>
                      </div> -->
                      <b-row>
                      <b-col  v-for="(ph,i) in item.media" :key="i"  md="12" :lg="item.media.length > 1 ? 6 : 12" >
                      <div @click="showPhoto(item,ph)" class="user-images position-relative overflow-hidden"> 
                      
                      
                      <a href="#"> <img :src="ph" class="img-fluid rounded" style='width:100%' alt="Responsive image" @click="$bvModal.show('modal-'+ph.id)" > 
                      </a>
                      
                      
                        <div class="image-hover-data">
                          <div class="product-elements-icon">
                            <ul class="d-flex align-items-center m-0 p-0 list-inline">
                              <li> <a href="#" class="pr-3 text-white"> {{item.totalLike}} <i class="ri-thumb-up-line"></i> </a> </li>
                              <li> <a href="#" class="pr-3 text-white"> {{item.totalComment}} <i class="ri-chat-3-line"></i> </a> </li>
                              <!-- <li> <a href="#" class="pr-3 text-white"> {{item.totalShare}} <i class="ri-share-forward-line"></i> </a> </li> -->
                            </ul>
                          </div>
                        </div>
                        <a href="#" @click="deletePostFromPhotos(item.id,item.media.length)" class="image-edit-btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"><i class="ri-delete-bin-line m-0"></i></a> </div>
                      </b-col>
                      </b-row>
                    </b-col>
                    <b-modal :id="'modal-'+modalId"  title="modalCaption" hide-footer size="xl">
                      <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
                        <div class="col-md-12 col-sm-12 p-3">   
                          <div class="card iq-card">
                            <div class="card-body iq-card-body profile-page p-0">
                              <div class="profile-header-image">
                                <div class="cover-container">
                                  <img :src="itemModal.img" alt="profile-bg" class="rounded img-fluid w-100">
                                </div>
                              </div>
                            </div>     
                          </div>
                        </div>
                      </div>   
                    </b-modal>
                  </b-row>
                </tab-content-item>
                <!-- <tab-content-item :active="false" id="your-photo" aria-labelled-by="pills-your-photo-tab">
                  <ProfileImage />
                </tab-content-item> -->
              </div>
            </template>
          </iq-card>
        </tab-content-item>
        <tab-content-item :active="false" id="profile-music" aria-labelled-by="pills-tips-tab">
          <iq-card>
            <template v-slot:body>
              <h2>Music</h2>
              <div class="mt-2" v-for="music in musicData" :key="music.id"> 
                <img src="/img/48.cb57727d.jpg" alt="story-img" class="img-fluid py-3"> 
                <a class="d-block" :href="music.musicLink" target="_blank">{{ music.musicLink }}</a>
                <!-- <hr> -->
                <!-- <img src="/img/48.cb57727d.jpg" alt="story-img" class="img-fluid py-3"> <a class="d-block"  href="#">https://www.google.com/search?</a> -->
                 </div>
            </template>
          </iq-card>
        </tab-content-item>
          <tab-content-item :active="false" id="profile-tips" aria-labelled-by="pills-tips-tab">
          <b-row class=" p-0 sm-px-2">
              <b-col lg="12" class="sm-p-0">        
                  <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
                    <div  v-for="post in socialTips" :key="post.id">
                      <social-post :post="post" :deletePostApi='deletePostApi' :deleteCommentApi='deleteCommentApi'></social-post>
                    </div>
                    <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
                    <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
              </b-col>      
            </b-row>
        </tab-content-item>
      </div>
    </b-col>
   </b-row>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import ProfileImage from './ProfileImage'
import About from './ProfileFriends/About'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import Post from '../../../Model/Post'
import { Posts } from '../../../FackApi/api/SocialPost'
import SocialPost from '../../Apps/Social/Components/SocialPost'
import RecentBirthdate from '../Bithdate/RecentBirthdate'
import axios from '../../../components/axios'
import InfiniteLoading from 'vue-infinite-loading';
import Events from "../../Apps/Social/Components/Events"
export default {
  name: 'Profile',
  mounted () {
    socialvue.index()
    var userData =  JSON.parse(localStorage.getItem('user'));
    this.user.name = userData.name
    this.user.avatar = userData.avatar
    var parent = this
    const config = {
      method: "get",
      url: "photos",
    };

    axios(config).then(res => {
      parent.postphotos = res.data.photos
    }).catch(err => {
      console.log(err.response);
    })

    const config1 = {
      method: "get",
      url: "authmusic",
    };

    axios(config1).then(res => {
      parent.musicData = res.data.posts
    }).catch(err => {
      console.log(err.response);
    })

  },
  components: {
    ProfileImage,
    AddSocialPost,
    SocialPost,
    About,
    RecentBirthdate,
    InfiniteLoading,
    Events
  },
  data () {
    return {
      user:{
        name: '',
        avatar:''
      },
      musicData:[],
      post: new Post(),
      socialPosts: [],
      itemModal:{
        img:'',
        totalComments:'',
        totalLikes:''
      },
      page:1,
      last_page:1,
      allLoadError:'',
      socialTips: [],
      requestUrl:'authposts',
      modalId:'',
      infiniteId: +new Date(),
      daybanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic2.png')})`
        },
       webanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic1.png')})`
        },
      suggestionEvent: [
        {
          img: require('../../../assets/images/page-img/42.png'),
          title: 'Iqonic Studio',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-1.jpg')
        },
        {
          img: require('../../../assets/images/page-img/43.png'),
          title: 'Cakes & Bakes ',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-2.jpg')
        }
      ],
      bdaybanner: [
        {
          img: require('../../../assets/images/dashboard-graphic2.png'),
          heading: 'birthday banner'
        },
        {
          img: require('../../../assets/images/dashboard-graphic1.png'),
          heading: 'wfd'
        }
      ],
      event: [
        {
          img: require('../../../assets/images/page-img/s4.jpg'),
          heading: 'Web Workshop',
          time: '1 hour ago'
        },
        {
          img: require('../../../assets/images/page-img/s5.jpg'),
          heading: 'Fun Events and Festivals',
          time: '4 hour ago'
        }
      ],
      social: [
        require('../../../assets/images/icon/08.png'),
        require('../../../assets/images/icon/09.png'),
        require('../../../assets/images/icon/10.png'),
        require('../../../assets/images/icon/11.png'),
        require('../../../assets/images/icon/12.png'),
        require('../../../assets/images/icon/13.png')
      ],
      lifeEvent: [
        {
          img: require('../../../assets/images/page-img/07.jpg'),
          heading: 'Started New Job at Apple',
          date: 'January 24, 2019'
        },
        {
          img: require('../../../assets/images/page-img/08.jpg'),
          heading: 'Freelance Photographer',
          date: 'January 24, 2019'
        }
      ],
      friends: [
        {
          img: require('../../../assets/images/user/05.jpg'),
          name: 'Anna Rexia'
        },
        {
          img: require('../../../assets/images/user/06.jpg'),
          name: 'Tara Zona'
        },
        {
          img: require('../../../assets/images/user/07.jpg'),
          name: 'Polly Tech'
        },
        {
          img: require('../../../assets/images/user/08.jpg'),
          name: 'Bill Emia'
        },
        {
          img: require('../../../assets/images/user/09.jpg'),
          name: 'Moe Fugga'
        },
        {
          img: require('../../../assets/images/user/10.jpg'),
          name: 'Hal Appeno '
        },
        {
          img: require('../../../assets/images/user/09.jpg'),
          name: 'Zack Lee'
        },
        {
          img: require('../../../assets/images/user/07.jpg'),
          name: 'Terry Aki'
        },
        {
          img: require('../../../assets/images/user/08.jpg'),
          name: 'Greta Life'
        }
      ],
      soicalInfo: [
        {
          name: 'Post',
          value: 690
        },
        {
          name: 'Followers',
          value: 90
        },
        {
          name: 'Following',
          value: 100
        }
      ],
      phtoes: [
        require('../../../assets/images/page-img/g1.jpg'),
        require('../../../assets/images/page-img/g1.jpg'),
        require('../../../assets/images/page-img/g2.jpg'),
        require('../../../assets/images/page-img/g3.jpg'),
        require('../../../assets/images/page-img/g4.jpg'),
        require('../../../assets/images/page-img/g5.jpg'),
        require('../../../assets/images/page-img/g6.jpg'),
        require('../../../assets/images/page-img/g7.jpg'),
        require('../../../assets/images/page-img/g8.jpg')
      ],

      profileImages: [
        {
          img: require('../../../assets/images/page-img/51.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/52.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/53.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 120
            },
            {
              class: 'ri-chat-3-line',
              value: 21

            },
            {
              class: 'ri-share-forward-line',
              value: 10
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/54.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 100
            },
            {
              class: 'ri-chat-3-line',
              value: 20

            },
            {
              class: 'ri-share-forward-line',
              value: 120
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/55.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 107
            },
            {
              class: 'ri-chat-3-line',
              value: 20

            },
            {
              class: 'ri-share-forward-line',
              value: 101
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/56.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 105
            },
            {
              class: 'ri-chat-3-line',
              value: 25

            },
            {
              class: 'ri-share-forward-line',
              value: 15
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/57.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 107
            },
            {
              class: 'ri-chat-3-line',
              value: 27

            },
            {
              class: 'ri-share-forward-line',
              value: 17
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/58.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 106
            },
            {
              class: 'ri-chat-3-line',
              value: 26

            },
            {
              class: 'ri-share-forward-line',
              value: 14
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/51.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/52.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/53.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 120
            },
            {
              class: 'ri-chat-3-line',
              value: 21

            },
            {
              class: 'ri-share-forward-line',
              value: 10
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/54.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 100
            },
            {
              class: 'ri-chat-3-line',
              value: 20

            },
            {
              class: 'ri-share-forward-line',
              value: 120
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/55.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 107
            },
            {
              class: 'ri-chat-3-line',
              value: 20

            },
            {
              class: 'ri-share-forward-line',
              value: 101
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/56.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 105
            },
            {
              class: 'ri-chat-3-line',
              value: 25

            },
            {
              class: 'ri-share-forward-line',
              value: 15
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/57.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 107
            },
            {
              class: 'ri-chat-3-line',
              value: 27

            },
            {
              class: 'ri-share-forward-line',
              value: 17
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/58.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 106
            },
            {
              class: 'ri-chat-3-line',
              value: 26

            },
            {
              class: 'ri-share-forward-line',
              value: 14
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/51.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/52.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 10
            },
            {
              class: 'ri-chat-3-line',
              value: 2

            },
            {
              class: 'ri-share-forward-line',
              value: 1
            }
          ]
        },
        {
          img: require('../../../assets/images/page-img/53.jpg'),
          otherInfo: [
            {
              class: 'ri-thumb-up-line',
              value: 120
            },
            {
              class: 'ri-chat-3-line',
              value: 21

            },
            {
              class: 'ri-share-forward-line',
              value: 10
            }
          ]
        }
      ],
      postphotos:[],
      friends: [
        { name: 'Paul Molive', role: 'Web Designer', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', role: 'trainee', image: require('../../../assets/images/user/user-01.jpg') }
      ],
        suggestions: [
        { name: 'Paul Molive', mutual_friend: 'Today', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', mutual_friend: 'Tomorrow', image: require('../../../assets/images/user/user-01.jpg') }
      ],
    }
  },
  methods: {
    deletePostApi(postId){
      var parent = this;
       const config = {
          method: "post",
          url: "deletepost",
          data:{
            id:postId,
          },
      };
        axios(config).then(res => {
          parent.socialPosts = parent.socialPosts.filter(socialPost =>  socialPost.id !== postId  )

        }).catch(err => {
            console.log(err);
        })
      
    },
    deleteCommentApi(commentId){
      var parent = this;

        const config = {
            method: "post",
            url: "deletecomment",
            data:{
              id:commentId,
            },
        };


        axios(config).then(res => {

          parent.socialPosts.map((socialPost)=> {
            socialPost.comments = socialPost.comments.filter(comment =>  comment.id !== commentId)
          })

        }).catch(err => {
            console.log(err.response);
        })
        
      
    },
    addPost (post) {
      this.socialPosts.unshift(post)
    },
    filterFun(typ){
      this.page = 1;
      this.socialTips = [];
      this.socialPosts = [];
      this.infiniteId += 1;
      this.requestUrl = typ;
      this.allLoadError = ''
    },
     showPhoto(item,ph){
      this.modalId = ph.id
      this.itemModal.img = ph
      this.itemModal.totalComments = item.totalComment;
      this.itemModal.totalLikes = item.totalLike;
    },
    async infiniteHandler($state) {
      var parent = this

      // if(parent.requestType === 'posts'){

      // }
        var config = {
          method: "get",
          url: parent.requestUrl,
          params: {
              page: parent.page,
          },
        };

        if(parent.page <= parent.last_page){
          await axios(config).then((response) => {
            parent.last_page = response.data.postpaginate.last_page;
         

            
            if (parent.page <= response.data.postpaginate.last_page) {
              parent.page += 1;

              if(parent.requestUrl === 'authposts'){
                parent.socialPosts.push(...response.data.posts)
              }else{
                parent.socialTips.push(...response.data.posts)
              }
              
              $state.loaded();
            } else {
              $state.complete();
            }
          }).catch(e =>{
            console.log(e.response)
          });
        }else{
          this.allLoadError = '(: Not Found '
        }
      
    },
    deletePostFromPhotos(postId,len){
      if(confirm('Are you sure you want to delete this post? ')){
        var con = true;
        if(len >  1){
          con = confirm(`This photo is associative with another ${len-1} photos,do you want to delete ?`)
        }
        if(con){
      var parent = this;
            const config = {
                method: "post",
                url: "deletepost",
                data:{
                  id:postId,
                },
            };
        axios(config).then(res => {
          parent.postphotos = parent.postphotos.filter(postphoto =>  postphoto.id !== postId  )

        }).catch(err => {
            console.log(err);
        })
        }
         
       }
     
      
    },
  }
}
</script>
